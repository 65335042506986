import React, { useState, useEffect } from 'react'
import { Upload, Button, Progress, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import * as JobFileApi from '../../api/jobfile.api'
import 'antd/dist/antd.css'
import { openNotificationWithIcon } from '../../utils'
import mixpanel from 'mixpanel-browser'
import { FILE_SIZE_MSG } from '../../constants/index'

const FileUploadForm = ({ user, job, setFiles, socket }) => {
	const [fileList, setFileList] = useState([])
	const allowedFileTypes = ['.doc', '.xls', '.xlsx', '.csv', '.pdf', '.jpg', '.jpeg', '.png', '.xlsm']
	const [progressCheck, setProgressCheck] = useState(0)
	const [checkStatus, setCheckStatus] = useState(false)
	const [uploading, setUploading] = useState(false)

	// const handleUpload = async () => {
	//   try {
	//     if (fileList.length === 0) {
	//       openNotificationWithIcon('error', 'Error', 'Please select a file to upload');
	//       return;
	//     }
	//     setUploading(true);
	//     let formData = new FormData();
	//     fileList.forEach((file) => {
	//       formData.append('files', file.originFileObj);
	//     });

	//     formData.append('user', user.id);
	//     formData.append('job', job.id);
	//     formData.append('uploadedBy', user.userType == 'customer' ? 'customer' : 'technician');
	//     setProgressCheck(0);
	//     let response = await JobFileApi.uploadFile(formData,user.id)

	//     if (response.status) {
	//       setFileList([])
	//       setProgressCheck(30)
	//       socket.emit('refresh-file-list', {
	//         jobId: job.id,
	//         job:job,
	//         userType: user.userType,
	//         meeting:true,

	//     });
	//       let jobIds = [job.id];
	// 			if(job && job.is_transferred && job.transfer_reference_job){
	// 				jobIds.push(job.transfer_reference_job);
	// 			}
	//       const updatedFiles = await JobFileApi.listOfUploadFile({jobIds: jobIds},user.id);
	//       setFiles(updatedFiles.files);
	//       setProgressCheck(70)

	//       openNotificationWithIcon('success', 'Success', "File uploaded successfully");
	//       mixpanel.identify(user.email);
	//       mixpanel.track(
	//         "File uploaded successfully",
	//         { JobId: job.id ,
	//           userId: user.id,
	//           userType: user.userType
	//         }
	//       );
	//       setProgressCheck(100);
	//       setTimeout(() => {
	//         setCheckStatus(false);
	//         setUploading(false);
	//         setProgressCheck(0)
	//       }, 2000);
	//     }
	//     else{
	//       openNotificationWithIcon('error', 'Error', 'Unauthorized access to upload file');
	//     }
	//   } catch (error) {
	//     console.error('Error uploading files:', error);

	//   }
	// };
	const handleUpload = async () => {
		try {
			if (fileList.length === 0) {
				openNotificationWithIcon('error', 'Error', 'Please select a file to upload')
				return
			}
			setUploading(true)
			let formData = new FormData()
			fileList.forEach((file) => {
				formData.append('files', file.originFileObj)
			})

			formData.append('user', user.id)
			formData.append('job', job.id)
			formData.append('uploadedBy', user.userType == 'customer' ? 'customer' : 'technician')
			setProgressCheck(0)
			// Optimistically add file to state
			const newFile = {
				fileName: fileList[0].name, // You can update this with the appropriate file name
				fileUrl: URL.createObjectURL(fileList[0].originFileObj), // Use the object URL for the file
				jobFileData: {
				  status: 'active',
				  job: job.id,
				  uploadedBy: user.userType,
				  fileName: fileList[0].name,
				  fileUrl: URL.createObjectURL(fileList[0].originFileObj),
				}
			  };
		  
			  // Add the newly uploaded file optimistically
			  setFiles(prevFiles => [newFile, ...prevFiles]);
			let response = await JobFileApi.uploadFile(formData, user.id)
			console.log('handle upload>>>>>>yy', response)
			if (response.status) {
				setFileList([])
				setProgressCheck(30)
				socket.emit('refresh-file-list', {
					jobId: job.id,
					job: job,
					userType: user.userType,
					meeting: true,
				})
				let jobIds = [job.id]
				if (job && job.is_transferred && job.transfer_reference_job) {
					jobIds.push(job.transfer_reference_job)
				}
				const updatedFiles = await JobFileApi.listOfUploadFile({ jobIds: jobIds }, user.id)
				setFiles(updatedFiles.files)
				setProgressCheck(70)

				openNotificationWithIcon('success', 'Success', 'File uploaded successfully')
				mixpanel.identify(user.email)
				mixpanel.track('File uploaded successfully', {
					JobId: job.id,
					userId: user.id,
					userType: user.userType,
				})
				setProgressCheck(100)
				setTimeout(() => {
					setCheckStatus(false)
					setUploading(false)
					setProgressCheck(0)
				}, 2000)
			} else {
				// Handle failure case and show detailed error message
				const errorMessage = response.errorDetails ? `Error: ${response.errorDetails.message}` : 'Unauthorized access to upload file'
				openNotificationWithIcon('error', 'Error', errorMessage)
				setUploading(false)
			}
		} catch (error) {
			console.error('Error uploading files:', error)
			setUploading(false)
      openNotificationWithIcon('error', 'Error', 'File upload functionality has an issue, please try again after some time');

		}
	}

	const handleFileListChange = ({ fileList: newFileList }) => {
		const fileSizeExceedsLimit = newFileList.some((file) => file.size > 100 * 1024 * 1024) // Check if any file size exceeds 100MB

		if (fileSizeExceedsLimit) {
			openNotificationWithIcon('error', 'Error', FILE_SIZE_MSG)
			setCheckStatus(false)
			return false
		}

		const invalidFiles = newFileList.filter((file) => !allowedFileTypes.includes(file.name.slice(file.name.lastIndexOf('.'))))

		if (invalidFiles.length > 0) {
			// Display a message informing the user about the invalid files
			openNotificationWithIcon(
				'error',
				'Error',
				`Invalid file(s) selected: ${invalidFiles
					.map((file) => file.name)
					.join(', ')}. Please select only the allowed file types: ${allowedFileTypes.join(', ')}`,
			)
			setCheckStatus(false)
			return
		}
		newFileList.length > 0 ? setCheckStatus(true) : setCheckStatus(false)
		setFileList(newFileList)
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center">
					<Upload
						fileList={fileList}
						onChange={handleFileListChange}
						multiple
						beforeUpload={() => false} // Prevent default upload behavior
						className="custom-upload"
					>
						<Button
							block
							className="w-100"
							style={{ backgroundColor: '#2db7f5', borderColor: '#2db7f5', color: '#fff', fontWeight: 600 }}
						>
							<UploadOutlined style={{ color: '#fff', marginRight: '8px' }} /> Select Files
						</Button>
					</Upload>
					<div className="my-3"></div>
					<Button
						block
						onClick={handleUpload}
						style={{
							whiteSpace: 'normal',
							backgroundColor: '#4EE1E2',
							color: '#fff',
							fontWeight: 600,
							width: '50%',
							height: 'auto',
							borderRadius: '10px',
						}}
						disabled={uploading}
					>
						{uploading ? <Spin size="small" /> : <span>Click Here to Upload</span>}
					</Button>
					{checkStatus && <Progress percent={progressCheck} />}
				</div>
			</div>
		</div>
	)
}

export default FileUploadForm
