import React, { useState } from 'react'
import * as JobFileApi from '../../api/jobfile.api'
import { openNotificationWithIcon } from '../../utils'
import { Pagination,Spin } from 'antd'
import mixpanel from 'mixpanel-browser'

const FileTable = ({ user, files = [], job, setFiles, socket }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 7 // Number of items per page
    const [isDeleting, setIsDeleting] = useState(null)

    let sortedFiles = [...files].sort((a, b) => new Date(b.jobFileData.updatedAt) - new Date(a.jobFileData.updatedAt));
    const startIndex = (currentPage - 1) * pageSize;
    const filesToShow = sortedFiles.slice(startIndex, startIndex + pageSize);

    const handleDelete = async (fileNameToDelete) => {
        try {
            setIsDeleting(fileNameToDelete);
            let response = await JobFileApi.deleteFile(
                {
                    fileName: fileNameToDelete,
                    userType: user.userType,
                },
                user.id,
            )

            if (response?.status) {
                openNotificationWithIcon('success', 'Success', response?.message)
                const updatedFiles = files.filter((file) => file.jobFileData.fileName !== fileNameToDelete);

                setFiles([...updatedFiles]); // Spread operator forces state update

                // Adjust current page if the last page becomes empty after deletion
                const totalPages = Math.ceil(updatedFiles.length / pageSize)
                if (currentPage > totalPages) {
                    setCurrentPage(totalPages) // Set to last non-empty page
                }
                setIsDeleting(null);
                socket.emit('refresh-file-list', {
                    jobId: job.id,
                    userType: user.userType,
                    job:job,
                    deleteFile:true,
                })
                mixpanel.identify(user.email)
                mixpanel.track('File delete successfully', {
                    JobId: job.id,
                    userId: user.id,
                    userType: user.userType,
                })
            } else {
                console.error('Error deleting file:', response)
                openNotificationWithIcon('error', 'Error', response?.message)
            }
        } catch (error) {
            console.error('Error deleting file:', error)
            openNotificationWithIcon('error', 'Error', `Failed to delete file ${fileNameToDelete}.`)
        }
    }
    const handleDownload = async (fileName) => {
        try {
           
            let response = await JobFileApi.downloadFile(
                {
                    fileName: fileName,
                },
                user.id,
            )
            if(response?.status ) {
            const link = document.createElement('a')
            link.href = response.url
            link.setAttribute('download', fileName)
            // Open the download link in a new tab
            window.open(link.href, '_blank');
            // document.body.appendChild(link)
            // link.click()
            // link.remove()
                openNotificationWithIcon('success', 'Success', response?.message)
                mixpanel.identify(user.email)
                mixpanel.track('File download successfully', {
                    JobId: job.id,
                    userId: user.id,
                    userType: user.userType,
                })
                
            } else {
                openNotificationWithIcon('error', 'Error', `Failed to download file`,response?.message)
            }
        } catch (error) {
            console.error('Error download file::', error)
            openNotificationWithIcon('error', 'Error', `Failed to download file ${fileName}.`)
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    return (
        <div className="uploadfile-container">
            {filesToShow.length === 0 ? (
                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '16px' }}>No files available</p>
            ) : (
                <div className="table-responsive">
                    <table className="uploadfile-table" style={{ width: '100%' }}>
                        <thead className="thead-light">
                            <tr style={{ backgroundColor: '#97ABB6' }}>
                                <th>File Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filesToShow.map((file, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                                    <td>{file.jobFileData.fileName}</td>
                                    <td>
                                        <div className="d-flex flex-wrap">
                                            {/* {!(
												user.userType === 'technician' &&
												job.is_transferred &&
												job.transfer_reference_job === file.jobFileData[0].job
											) &&
												(user.userType === 'technician' ||
													(file.jobFileData[0].uploadedBy === 'customer' && job.id == file.jobFileData[0].job)) && (
													<button
														className="deleteFile-button mr-2"
														onClick={() => handleDelete(file.name)}
														disabled={isDeleting}
													>
														Delete
													</button>
												)} */}
                                              {!(file.jobFileData.uploadedBy === 'customer' && user.userType === 'technician') && !(file.jobFileData.uploadedBy === 'technician' && user.userType === 'customer') && !(job.is_transferred && job.transfer_reference_job === file.jobFileData.job) && (
                                                <button className="deleteFile-button" onClick={() => handleDelete(file.jobFileData.fileName)} disabled={isDeleting}> {isDeleting === file.jobFileData.fileName ? (
                                                    <Spin size="small" />
                                                ) : (
                                                    <span>Delete</span>
                                                )}</button>
                                            )}

                                            {user.userType === 'customer' &&
                                                job.is_transferred &&
                                                job.transfer_reference_job === file.jobFileData.job && (
                                                    <button
                                                        className="deleteFile-button mr-2"
                                                        onClick={() => handleDelete(file.jobFileData.fileName)}
                                                        disabled={isDeleting}
                                                    >
                                                         {isDeleting === file.jobFileData.fileName ? (
                                                        <Spin size="small" />
                                                    ) : (
                                                        <span>Delete</span>
                                                    )}
                                                    </button>
                                                )}
                                            <button
                                                className="deleteFile-button"
                                                style={{ backgroundColor: '#4EE1E2' }}
                                                onClick={() => handleDownload(file.jobFileData.fileName)}
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {files.length > pageSize && <Pagination current={currentPage} total={files.length} pageSize={pageSize} onChange={onPageChange} />}
                </div>
            )}
        </div>
    )
}

export default FileTable
